/* eslint-disable no-useless-catch */
/* eslint-disable class-methods-use-this */
import { amgApi } from '@/service/axios';

class ProfileService {
  async getPsychoEvaluation() {
    try {
      const { data, status } = await amgApi.post('/rrhh-extra/get-psycho-evaluation');
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async updatePsycholaboralTest(body) {
    try {
      const { data, status } = await amgApi.post('/rrhh-extra/update-psycho-evaluation', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async uploadPsycholaboralReport(body) {
    try {
      const { data, status } = await amgApi.post('/rrhh-extra/upload-psycho-report-template', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async getApplicantsWithoutReport(body) {
    try {
      const { data, status } = await amgApi.post('/rrhh-extra/get-applicants-without-report', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }
}

export default new ProfileService();
