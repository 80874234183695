<template>
  <div>
    <b-modal
      ref="modal-add-psycho-report"
      no-close-on-backdrop
      size="md"
      :header-bg-variant="isDarkSkin ? 'dark' : 'white'"
      @hidden="$emit('hidden')"
    >
      <validation-observer ref="observer">
        <h1 class="text-warning row justify-content-center my-3">
          <strong class="text-uppercase">
            Psycholaboral Report of {{ applicantName }}
          </strong>
        </h1>
        <div
          v-for="(item, index) in psychoEvaluationArrayCopy"
          :key="index"
          class="row justify-content-center my-1 mx-2"
        >
          <div
            class="col-md-12"
          >
            <h4>
              <strong>{{ index + 1 }}. {{ item.name }}</strong>
            </h4>
            <validation-provider
              v-slot="{ errors }"
              rules="required"
            >
              <b-form-textarea
                id="textarea"
                v-model="item.text"
                placeholder="Enter something..."
                rows="5"
                max-rows="9"
                :readonly="isReadOnly"
                :state="errors[0] ? false : null"
              /><small
                v-if="errors[0]"
                class="text-danger"
              >
                {{ errors[0] }}
              </small>
            </validation-provider>
          </div>
        </div>
        <h3 class="text-info row justify-content-center mt-4">
          <strong>
            Report Status
          </strong>
        </h3>
        <div
          class="row justify-content-center"
        >
          <div
            v-if="!isReadOnly"
            class="col-md-5 mb-3"
          >
            <validation-provider
              v-slot="{ errors }"
              rules="required"
            >
              <v-select

                id="select-shift"
                v-model="applicantStatus"
                placeholder="Please select applicant psycholaboral status"
                label="text"
                :options="optionsApplicantStatus"
                value-field="id"
                :reduce="(option) => option.id"
                :style="errors[0] ? 'border: 1px solid red;':''"
                width="50%"
              /><small
                v-if="errors[0]"
                class="text-danger"
              >
                {{ errors[0] }}
              </small>
            </validation-provider>

            <b-form-textarea
              v-if="applicantStatus == 1"
              id="textareaObs"
              v-model="observation"
              class="mt-2"
              placeholder="Enter something..."
              rows="5"
              max-rows="9"
              :readonly="isReadOnly"
            />

          </div>

          <div
            v-else
            class="col-md-5 mb-3 mt-1"
          >
            <b-form-input
              v-model="selectedAplicantObj.status"
              :readonly="isReadOnly"
              :state="isApproved"
            />
            <b-form-textarea
              v-if="selectedAplicantObj.status == 'Approved with observations'"
              id="textareaObs"
              v-model="selectedAplicantObj.observation"
              class="mt-2"
              placeholder="Enter something..."
              rows="5"
              max-rows="9"
              :readonly="isReadOnly"
            />
          </div>
        </div>
      </validation-observer>
      <template #modal-footer>
        <div>
          <b-button
            v-if="!isReadOnly"
            variant="primary"
            @click="uploadPsycholaboralReport()"
          ><feather-icon
            icon="SaveIcon"
            class="mr-1"
          />
            SAVE REPORT</b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Service from './psycholaboral-evaluation-settings.service';

export default {
  props: {
    psychoEvaluationArray: {
      type: Array,
      default: null,
    },
    applicantId: {
      type: Number,
      default: null,
    },
    selectedAplicantObj: {
      type: Object,
      default: null,
    },
    applicantName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      optionsApplicantStatus:
        [
          { id: 2, text: 'Approved without observations' },
          { id: 1, text: 'Approved with observations' },
          { id: 3, text: 'Rejected' },
        ],
      psychoEvaluationArrayCopy: [],
      applicantStatus: null,
      observation: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    isReadOnly() {
      if (this.selectedAplicantObj) { if (this.selectedAplicantObj.body) return true; }
      return false;
    },
    isApproved() {
      if (this.selectedAplicantObj.status === 'Approved without observations') return true;
      return false;
    },
  },
  mounted() {
    this.toggleModal('modal-add-psycho-report');
    if (this.psychoEvaluationArray) this.psychoEvaluationArrayCopy = [...this.psychoEvaluationArray];
    if (this.selectedAplicantObj) { if (this.selectedAplicantObj.body) this.psychoEvaluationArrayCopy = JSON.parse(this.selectedAplicantObj.body); }
  },
  methods: {
    async uploadPsycholaboralReport() {
      const validation = await this.$refs.observer.validate();
      if (!validation) return;
      const resultState = await this.showConfirmSwal();
      if (!resultState.isConfirmed) return;

      this.psychoEvaluationArrayCopy.forEach((item, index) => {
        item.position = index + 1;
      });

      try {
        this.addPreloader();
        const obj = {
          idUser: this.currentUser.user_id,
          idApplicant: this.applicantId,
          psychoEvaluationArray: this.psychoEvaluationArrayCopy,
          applicantStatus: this.applicantStatus,
          observation: this.observation,
        };
        const res = await Service.uploadPsycholaboralReport(obj);
        if (res.status === 200) {
          this.removePreloader();
          this.showSuccessSwal();
          this.$emit('closeModal');
          this.$emit('refreshButton');
        }
      } catch (error) {
        console.log(error);
        this.removePreloader();
      }
    },
  },
};
</script>
