<template>
  <div>
    <validation-observer ref="observer">
      <div class="row justify-content-center my-2">
        <b-button
          variant="info"
          @click="revertChanges()"
        ><feather-icon
           icon="RewindIcon"
           size="15"
         />
          <strong> REVERT CHANGES</strong>
        </b-button>
        <b-button
          variant="primary ml-1"
          @click="addObject()"
        ><feather-icon
           icon="FolderPlusIcon"
           size="15"
         />
          <strong> ADD SECTION</strong>
        </b-button>
        <b-button
          variant="success ml-1"
          @click="updatePsycholaboralTest()"
        ><feather-icon
           icon="SaveIcon"
           size="15"
         />
          <strong> SAVE TEMPLATE</strong>
        </b-button>
        <b-button
          variant="warning ml-1"
          @click="openSelectApplicantModal(1)"
        ><feather-icon
           icon="FileTextIcon"
           size="15"
         />
          <strong> MAKE REPORT</strong>
        </b-button>
        <b-button
          variant="warning ml-1"
          @click="openSelectApplicantModal(2)"
        ><feather-icon
           icon="EyeIcon"
           size="15"
         />
          <strong> VIEW REPORT</strong>
        </b-button>
      </div>
      <h1 class="row justify-content-center mb-2">
        <strong>
          Psycholaboral Report Template
        </strong>
      </h1>
      <draggable
        :key="listKey"
        v-model="psychoEvaluationArray"
        :options="dragOptions"
      >
        <div
          v-for="(item, index) in psychoEvaluationArray"
          :key="index"
          class="row justify-content-center mb-1"
        >
          <div
            class="col-md-7"
          >
            <b-list-group>
              <b-list-group-item
                :class="{
                  'border-warning': item.isEditable,
                  'border-primary': item.id == null,
                }"
              >
                <h4 v-if="!item.isEditable">
                  <strong>{{ index + 1 }}. {{ item.name }}</strong>
                </h4>
                <h4
                  v-if="item.isEditable"
                  class="text-warning"
                >
                  <strong>EDIT TITLE</strong>
                </h4>
                <validation-provider
                  v-slot="{ errors }"
                  rules="required|max:255"
                  name="title"
                >

                  <b-form-input
                    v-if="item.isEditable"
                    v-model="newTitle"
                    placeholder="Enter a title"
                    :class="errors[0]"
                    :state="errors.length > 0 ? false : null"
                  />
                </validation-provider>
                <span>
                  {{ loreipsum }}
                </span>
              </b-list-group-item>

            </b-list-group>
          </div>
          <div
            class="col-md-1 align-items-center"
          >
            <div class="row my-1">
              <b-button
                v-if="!editMode"
                variant="primary"
                @click="changeRowStatus(index)"
              >
                <feather-icon
                  icon="EditIcon"
                  size="15"
                />
              </b-button>
            </div>
            <div class="row">
              <b-button
                v-if="!editMode"
                variant="danger"
                @click="deleteRow(item,index)"
              >
                <feather-icon
                  icon="Trash2Icon"
                  size="15"
                />
              </b-button>
            </div>

            <div class="row my-1">
              <b-button
                v-if="item.isEditable"
                variant="success"
                @click="saveTitle(index)"
              >
                <feather-icon
                  icon="CheckIcon"
                  size="15"
                />
              </b-button>
            </div>
            <div class="row">
              <b-button
                v-if="item.isEditable"
                variant="danger"
                @click="revertRowStatus(item,index)"
              >
                <feather-icon
                  icon="XIcon"
                  size="15"
                />
              </b-button>
            </div>
          </div>
        </div>
      </draggable>
      <div class="row justify-content-center">
        <b-button
          id="add-object-button-bottom"
          variant="success"
          @click="addObject()"
        >
          <strong>+</strong>
        </b-button>
      </div>
    </validation-observer>
    <create-psycholaboral-modal
      v-if="modal"
      :psycho-evaluation-array="psychoEvaluationArray"
      :applicant-id="selectedAplicantId"
      :selected-aplicant-obj="selectedAplicantObj"
      :applicant-name="selectedAplicantObj.name"
      @hidden="modal = false"
      @closeModal="closeModal()"
      @refreshButton="refreshButton()"
    />
    <b-modal
      v-model="modalSelectApplicant"
      no-close-on-backdrop
      centered
      size="sm"
      :title="'CHOOSE AN APPLICANT TO ' + modalTitle + ' HIS/HER PSYCHOLABORAL REPORT'"
      @hidden="modalSelectApplicant = false"
    >
      <validation-observer ref="observer2">
        <strong class="text-warning">
          Please select an applicant , or type him/her name to search
        </strong>
        <br>
        <br>
        <validation-provider
          v-slot="{ errors }"
          rules="required"
          name="select-applicant"
        >
          <v-select
            id="select-applicant"
            v-model="selectedAplicantId"
            placeholder="select an applicant ..."
            label="name"
            :options="applicantsArray"
            value-field="id"
            :reduce="(option) => option.id"
            width="100%"
            :class="
              errors[0]
                ? 'border-danger'
                : ''
            "
            @input="selectApplicant()"
          />
          <small
            v-if="errors.length > 0"
            class="text-danger"
          >
            {{ errors[0] }}
          </small>
        </validation-provider>
      </validation-observer>
      <template #modal-footer>
        <div>
          <b-button
            v-if="buttonConditioner"
            :variant="option == 1 ? 'success' : 'info'"
            @click="openReportModal()"
          ><feather-icon
             icon="SaveIcon"
             class="mr-1"
           />
            {{ modalTitle }} A REPORT</b-button>
          <strong
            v-else
            class="text-warning"
          >This applicant {{ alertString }} a Pycholaboral Test</strong>
        </div></template>
    </b-modal>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapGetters } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import Service from './psycholaboral-evaluation-settings.service';
import CreatePsycholaboralModal from './CreatePsycholaboralModal.vue';

export default {
  components: {
    draggable,
    CreatePsycholaboralModal,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      alertString: '',
      modalTitle: '',
      modalSelectApplicant: false,
      modal: false,
      newObject: {
        id: null,
        name: 'ADD NEW TITLE',
        created_by: 0,
        updated_by: null,
        created_at: '',
        updated_at: null,
        position: null,
        isEditable: false,
      },
      editMode: false,
      newTitle: '',
      listKey: 0,
      selectedAplicantId: null,
      selectedAplicantObj: null,
      applicantsArray: [],
      psychoEvaluationArray: [],
      psychoEvaluationArrayDuplicate: [],
      psychoEvaluationArrayDeleted: [],
      viewPsychoEvaluationArray: [],
      dragOptions: {
        animation: 200, // Animation duration in milliseconds
      },
      loreipsum: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed fermentum eleifend arcu, at tristique ligula convallis a.Phasellus nec dapibus est, eu suscipit sapien. Quisque vel tortor in libero viverra auctor. Nullam rhoncus massa eu ex aliquam, ut posuere risus suscipit. Sed vel velit non justo vestibulum efficitur. Nulla facilisi. Aliquam erat volutpat. Nullam malesuada libero sed odio interdum, ac fringilla sapien euismod. Fusce nec erat vel augue hendrerit feugiat.',
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    buttonConditioner() {
      if (this.selectedAplicantObj) {
        if (!this.selectedAplicantObj.body && this.option === 2) return false;
        if (this.selectedAplicantObj.body && this.option === 1) return false;
      }
      return true;
    },
  },
  mounted() {
    this.getPsychoEvaluation();
    this.getApplicantsWithoutReport();
  },
  methods: {

    closeModal() {
      this.modal = false;
      this.modalSelectApplicant = false;
      this.selectedAplicantId = null;
      this.selectedAplicantObj = null;
    },

    refreshButton() {
      this.getPsychoEvaluation();
      this.getApplicantsWithoutReport();
    },

    addRowStatus() {
      this.psychoEvaluationArray.forEach(item => {
        item.isEditable = false;
      });
    },

    deleteRow(item, index) {
      if (item.id != null) this.psychoEvaluationArrayDeleted.push(item);
      if (index >= 0 && index < this.psychoEvaluationArray.length) {
        this.psychoEvaluationArray.splice(index, 1);
      }
    },

    async revertChanges() {
      const resultState = await this.showConfirmSwal();
      if (!resultState.isConfirmed) return;
      this.psychoEvaluationArray = JSON.parse(JSON.stringify(this.psychoEvaluationArrayDuplicate));
      this.addRowStatus();
      this.psychoEvaluationArrayDeleted = [];
      this.listKey += 1;
    },

    selectApplicant() {
      this.selectedAplicantObj = this.applicantsArray.find(obj => obj.id === this.selectedAplicantId);
    },

    async openReportModal() {
      const validation = await this.$refs.observer2.validate();
      if (!validation) return;
      this.modal = true;
    },

    openSelectApplicantModal(option) {
      if (option === 1) {
        this.option = option;
        this.modalTitle = 'MAKE';
        this.alertString = 'has';
      }
      if (option === 2) {
        this.option = option;
        this.modalTitle = 'VIEW';
        this.alertString = 'dont has';
      }
      this.modalSelectApplicant = true;
    },

    changeRowStatus(index2) {
      this.editMode = true;
      this.psychoEvaluationArray.forEach((item, index) => {
        if (index === index2) {
          item.isEditable = true;
          this.newTitle = item.name;
        }
        if (index !== index2)item.isEditable = false;
      });
      this.listKey += 1;
    },

    revertRowStatus() {
      this.editMode = false;
      this.psychoEvaluationArray.forEach(item => {
        item.isEditable = false;
      });
      this.newTitle = '';
      this.listKey += 1;
    },

    async saveTitle(index2) {
      if (this.newTitle == null || this.newTitle === '') {
        this.showWarningSwal('Title cannot be empty');
        return;
      }
      this.editMode = false;
      this.psychoEvaluationArray.forEach((item, index) => {
        if (index === index2) {
          item.name = this.newTitle;
        }
        item.isEditable = false;
      });
      this.listKey += 1;
    },

    addObject() {
      this.psychoEvaluationArray.push({ ...this.newObject });
      this.listKey += 1;
      const elementToScrollTo = document.getElementById('add-object-button-bottom');
      if (elementToScrollTo) {
        elementToScrollTo.scrollIntoView({ behavior: 'smooth' });
      }
    },

    async getPsychoEvaluation() {
      try {
        this.addPreloader();
        const res = await Service.getPsychoEvaluation();
        if (res.status === 200) {
          this.psychoEvaluationArray = res.data;
          this.psychoEvaluationArrayDuplicate = JSON.parse(JSON.stringify(res.data));
          this.removePreloader();
        }
      } catch (error) {
        console.log(error);
        this.removePreloader();
      }
    },

    async getApplicantsWithoutReport() {
      try {
        this.addPreloader();
        const res = await Service.getApplicantsWithoutReport();
        if (res.status === 200) {
          this.applicantsArray = res.data;
          this.addRowStatus();
          this.removePreloader();
        }
      } catch (error) {
        console.log(error);
        this.removePreloader();
      }
    },

    async updatePsycholaboralTest() {
      const resultState = await this.showConfirmSwal();

      if (!resultState.isConfirmed) return;

      if (this.psychoEvaluationArray.length === 0) {
        this.showWarningSwal('This template has no titles ,add at least one');
        return;
      }

      this.psychoEvaluationArray.forEach((item, index) => {
        item.position = index + 1;
      });

      try {
        this.addPreloader();
        const obj = {
          idUser: this.currentUser.user_id,
          psychoEvaluationArray: this.psychoEvaluationArray,
          psychoEvaluationArrayDeleted: this.psychoEvaluationArrayDeleted,
        };
        const res = await Service.updatePsycholaboralTest(obj);
        if (res.status === 200) {
          this.psychoEvaluationArray = res.data;
          this.psychoEvaluationArrayDuplicate = JSON.parse(JSON.stringify(res.data));
          this.addRowStatus();
          this.removePreloader();
          this.showSuccessSwal();
        }
      } catch (error) {
        console.log(error);
        this.removePreloader();
      }
    },

  },
};
</script>
